import { VehicleUpdateAction, VehicleUpdateState } from '../../type';
import { SET_VEHICLE_UPDATES } from '../actionTypes';

const initialState: VehicleUpdateState = {
  vehicleUpdates: [],
};

initialState.vehicleUpdates = [];

export default function (state = initialState, action: VehicleUpdateAction) {
  switch (action.type) {
    case SET_VEHICLE_UPDATES: {
      Object.assign(state.vehicleUpdates, action.vehicleUpdates);
      return { ...state };
    }
    default:
      return state;
  }
}
