import axios from 'axios';
import { API_URL, GLOBAL_CONFIG_URL } from './constants';
import { GlobalConfig, VehicleUpdate } from './type';

export const getVehicleUpdates = async (
  deploymentZone: string
): Promise<VehicleUpdate[]> => {
  const res = await axios({
    method: 'GET',
    url: API_URL + '/vehicle/update?deployment_zone=' + deploymentZone,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'json',
  });
  return res.data;
};

export const getGlobalConfig = async (): Promise<GlobalConfig> => {
  const res = await axios({
    method: 'GET',
    url: GLOBAL_CONFIG_URL,
    responseType: 'json',
  });
  return res.data;
};
