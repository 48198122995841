import React from 'react';
import { Marker, Popup } from 'react-map-gl';
import { PositionType } from 'react-map-gl/src/utils/dynamic-position';
import { ReactComponent as StopMarkerIcon } from '../img/place_black_36dp.svg';
import { Route, RouteStop } from '../type';

const StopMarkers = (props: StopMarkersProps) => {
  const { route, pinPopups } = props;

  return (
    <>
      {route.stops.map((stop, index) => (
        <StopMarker key={index} {...{ stop, pinPopup: pinPopups }} />
      ))}
    </>
  );
};

interface StopMarkersProps {
  route: Route;
  pinPopups: boolean;
}

const StopMarker = (props: StopMarkerProps) => {
  const { stop, pinPopup } = props;
  const [showPopup, togglePopup] = React.useState(pinPopup);
  const [anchorPosition, setAnchorPosition] = React.useState<PositionType>(
    'bottom'
  );

  React.useEffect(() => {
    setAnchorPosition(stop.anchor as PositionType);
  }, []);

  return (
    <>
      {showPopup && (
        <Popup
          latitude={stop.lat}
          longitude={stop.lon}
          closeButton={false}
          onClose={() => togglePopup(!showPopup)}
          anchor={anchorPosition}
          offsetTop={-10}
          offsetLeft={-2}
          className='stop-popup'
        >
          <div>{stop.name}</div>
        </Popup>
      )}
      <Marker
        latitude={stop.lat}
        longitude={stop.lon}
        className='stop-marker'
        offsetLeft={-20}
        offsetTop={-30}
      >
        <StopMarkerIcon
          fill='#1F1F1F'
          onClick={() => {
            togglePopup(!showPopup);
          }}
        />
      </Marker>
    </>
  );
};

interface StopMarkerProps {
  stop: RouteStop;
  pinPopup: boolean;
}

export default StopMarkers;
