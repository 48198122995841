import React from 'react';
import ReactMapGL, { WebMercatorViewport } from 'react-map-gl';
import RoutePolyline from './Polyline';
import StopMarkers from './StopMarker';
import VehicleMarkers from './VehicleMarkers';
import { ACCESS_TOKEN, MAY_MOBILITY_COLORS } from '../constants';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Route, Viewport } from '../type';
import { getBoundsOfLines } from '../mapUtils';

const RouteMap = (props: RouteMapProps) => {
  const { mapStyle, mayId, route, onlyShowCurrentVehicle, pinPopups } = props;
  const deploymentZone = route.deploymentZone;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [viewport, setViewport] = React.useState(() => {
    const rawBounds = getBoundsOfLines(route.lineCoordinates).toArray() as [
      [number, number],
      [number, number]
    ];
    return new WebMercatorViewport({
      width: (window as Window).innerWidth,
      height: (window as Window).innerHeight,
    }).fitBounds(rawBounds, {
      padding: Math.min(20, (window as Window).innerWidth / 2),
    }) as Viewport;
  });

  return (
    <ReactMapGL
      {...viewport}
      // the viewport width/height properties (in pixels) do not cause the map
      // to resize when they change, so override them to make the map always
      // take up the full window
      width='100vw'
      height='100vh'
      mapboxApiAccessToken={ACCESS_TOKEN}
      mapStyle={mapStyle}
      onViewportChange={(nextViewport: Viewport) => setViewport(nextViewport)}
    >
      <StopMarkers {...{ route, pinPopups }} />
      <VehicleMarkers {...{ mayId, onlyShowCurrentVehicle, deploymentZone }} />
      {route.lineCoordinates.map((lineCoordinates, i) => (
        <RoutePolyline
          key={i}
          id={i}
          {...{
            points: lineCoordinates,
            color: MAY_MOBILITY_COLORS.LIGHT_BLUE,
            lineWidth: 6,
          }}
        />
      ))}
    </ReactMapGL>
  );
};

export interface RouteMapProps {
  mayId: string;
  route: Route;
  onlyShowCurrentVehicle: boolean;
  pinPopups: boolean;
  mapStyle: string;
}

export default RouteMap;
