import {
  GlobalConfig,
  GlobalConfigAction,
  VehicleUpdate,
  VehicleUpdateAction,
} from '../type';
import { SET_GLOBAL_CONFIG, SET_VEHICLE_UPDATES } from './actionTypes';

export const setVehicleUpdates = (
  vehicleUpdates: VehicleUpdate[]
): VehicleUpdateAction => {
  return { type: SET_VEHICLE_UPDATES, vehicleUpdates };
};

export const setGlobalConfig = (
  globalConfig: GlobalConfig
): GlobalConfigAction => {
  return { type: SET_GLOBAL_CONFIG, globalConfig };
};
