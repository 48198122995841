import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import RouteMap, { RouteMapProps } from './components/RouteMap';
import Legend, { LegendProps } from './components/Legend';
import useQuery from './hooks/useQuery';
import routes from './routes.json';
import { setGlobalConfig, setVehicleUpdates } from './redux/actions';
import { useAppDispatch } from './redux/hooks';
import { getGlobalConfig, getVehicleUpdates } from './requests';
import './styles.css';
import { Route } from './type';

const RoutedApp = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

const getVehicleRoute = (routeName: string): Route => {
  function isRoute(route: Route) {
    return route.id === routeName || route.alternateIds.includes(routeName);
  }
  const route = routes.find(isRoute);
  if (route !== undefined) {
    return route;
  } else {
    throw Error(`Invalid route name, ${routeName}, check routes.json`);
  }
};

const App = () => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const [routeMapProps, setRouteMapProps] = useState<RouteMapProps | undefined>(
    undefined
  );
  const [legendProps, setLegendProps] = useState<LegendProps>({
    title: 'Default Legend',
    weekdayHours: '7 am - 7 pm',
    weekendHours: 'No service',
  });

  const hydrateStore = (deploymentZone: string) => {
    getVehicleUpdates(deploymentZone)
      .then((v) => {
        dispatch(setVehicleUpdates(v));
      })
      .catch((e) => console.error(e));
    // TODO: this request to global config is being blocked by CORS
    // getGlobalConfig()
    //   .then((g) => {
    //     dispatch(setGlobalConfig(g));
    //   })
    //   .catch((e) => console.error(e));
  };

  useEffect(() => {
    const mayId = query.get('may_id');
    const routeName = query.get('route');
    if (mayId !== null && routeName !== null) {
      const route = getVehicleRoute(routeName);
      hydrateStore(route.deploymentZone);
      setRouteMapProps({
        mayId,
        route,
        onlyShowCurrentVehicle: mayId !== 'all',
        pinPopups: mayId !== 'all',
        mapStyle: 'mapbox://styles/griffinobeid/ckoenu15m47fc17qhbsheju85',
        // mapStyle: 'mapbox://styles/griffinobeid/ckoenpcd128lf18mswwn30hri' // Japanese map style
      });
      setLegendProps({
        title: route.title,
        // TODO: put this in the json for each route
        weekdayHours: route.weekdayHours,
        weekendHours: route.weekendHours,
      });
    }
  }, []);

  return (
    <div id='app'>
      {routeMapProps ? (
        <>
          <Legend {...legendProps} />
          <RouteMap {...routeMapProps} />
        </>
      ) : (
        <p>Error: you must specify may_id and route</p>
      )}
    </div>
  );
};

export default RoutedApp;
