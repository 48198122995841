import React from 'react';
import { Marker, MarkerProps } from 'react-map-gl';
import { OBSOLETE_DATA_TIMEOUT_SEC } from '../constants';
import useInterval from '../hooks/useInterval';
import genericAutonomousIcon from '../img/may_logo_green.png';
import genericManualIcon from '../img/may_logo_black.png';
/* TODO: once the global config request is fixed in App.tsx uncomment this
import lexusAutonomousIcon from '../img/may_lexus_map_icon.png';
import lexusManualIcon from '../img/may_lexus_map_icon_orange_ring.png';
*/
import { setVehicleUpdates } from '../redux/actions';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { getVehicleUpdates } from '../requests';
import { Platform, VehicleUpdate } from '../type';

const VehicleMarker = (props: VehicleMarkerProps) => {
  const { vehicleUpdate, platform } = props;

  const markerProps: MarkerProps = {
    longitude: vehicleUpdate.longitude,
    latitude: vehicleUpdate.latitude,
    className: 'vehicle-marker',
    offsetLeft: -25,
    offsetTop: -25,
  };

  const getPlatformImage = (): [string, string] => {
    switch (platform) {
      // TODO: once the global config request is fixed in App.tsx uncomment this
      // case 'rx450h-v1':
      //   return [lexusAutonomousIcon, lexusManualIcon];
      default:
        return [genericAutonomousIcon, genericManualIcon];
    }
  };

  const getMarkerImage = (): React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > => {
    const [autonomousVehicleIcon, manualVehicleIcon] = getPlatformImage();
    if (vehicleUpdate.run_level === 7) {
      return <img src={autonomousVehicleIcon} />;
    } else {
      return <img src={manualVehicleIcon} />;
    }
  };

  return <Marker {...markerProps}>{getMarkerImage()}</Marker>;
};

interface VehicleMarkerProps {
  vehicleUpdate: VehicleUpdate;
  platform: Platform | undefined;
}

const dateTimeIsObsolete = (lastUpdateTime: string): boolean => {
  const updateTimeDate = new Date(lastUpdateTime);
  const now = new Date();
  const diffInSeconds = Math.abs(
    (now.getTime() - updateTimeDate.getTime()) / 1000
  );
  return diffInSeconds > OBSOLETE_DATA_TIMEOUT_SEC;
};

const validateVehicleUpdates = (
  vehicleUpdates: VehicleUpdate[]
): VehicleUpdate[] => {
  const validatedUpdates: VehicleUpdate[] = [];
  for (const v of vehicleUpdates) {
    if (v.longitude !== 0 && v.latitude !== 0) {
      if (!dateTimeIsObsolete(v.update_time)) {
        validatedUpdates.push(v);
      }
    }
  }
  return validatedUpdates;
};

const VehicleMarkers = (props: VehicleMarkersProps) => {
  const { deploymentZone, mayId, onlyShowCurrentVehicle } = props;
  const dispatch = useAppDispatch();
  const globalConfig = useAppSelector((state) => state.globalConfig);
  const filteredVehicles: VehicleUpdate[] = useAppSelector((state) => {
    if (onlyShowCurrentVehicle) {
      return validateVehicleUpdates(
        state.vehicles.vehicleUpdates.filter((v) => v.may_id === mayId)
      );
    } else {
      return validateVehicleUpdates(state.vehicles.vehicleUpdates);
    }
  });

  const pollVehicleUpdates = async () => {
    const vehicleUpdates = await getVehicleUpdates(deploymentZone);
    dispatch(setVehicleUpdates(vehicleUpdates));
  };

  useInterval(pollVehicleUpdates, 2000);

  return (
    <>
      {filteredVehicles.map((v) => {
        return (
          <VehicleMarker
            key={v.may_id}
            vehicleUpdate={v}
            platform={globalConfig.may_ids.get(v.may_id)?.platform}
          />
        );
      })}
    </>
  );
};

interface VehicleMarkersProps {
  mayId: string;
  onlyShowCurrentVehicle: boolean;
  deploymentZone: string;
}

export default VehicleMarkers;
