import React from 'react';
import genericAutonomousIcon from '../img/may_logo_green.png';
import genericManualIcon from '../img/may_logo_black.png';
/* TODO: once the global config request is fixed in App.tsx uncomment this
import lexusAutonomousIcon from '../img/may_lexus_map_icon.png';
import lexusManualIcon from '../img/may_lexus_map_icon_orange_ring.png';
*/
import { ReactComponent as StopMarkerIcon } from '../img/place_black_36dp.svg';

const Legend = (props: LegendProps) => {
  const { title, weekdayHours, weekendHours } = props;

  return (
    <div className='legend'>
      <h2>{title}</h2>
      {weekdayHours || weekendHours ? <h3>Hours of Operation</h3> : null}
      {weekdayHours ? <p>M-F: {weekdayHours}</p> : null}
      {weekendHours ? <p>S-S: {weekendHours}</p> : null}
      <h3>Legend</h3>
      <div className='legend-item'>
        <img src={genericAutonomousIcon} />
        <span>Shuttle in autonomy</span>
      </div>
      <div className='legend-item'>
        <img src={genericManualIcon} />
        <span>Shuttle in manual</span>
      </div>
      {/* TODO: once the global config request is fixed in App.tsx uncomment this */}
      {/* <div className='legend-item'>
        <img src={lexusAutonomousIcon} />
        <span>Lexus in autonomy</span>
      </div>
      <div className='legend-item'>
        <img src={lexusManualIcon} />
        <span>Lexus in manual</span>
      </div> */}
      <div className='legend-item'>
        <StopMarkerIcon />
        <span>Route stop</span>
      </div>
    </div>
  );
};

export interface LegendProps {
  title: string;
  weekdayHours: string;
  weekendHours: string;
}

export default Legend;
