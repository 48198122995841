import { GlobalConfig, GlobalConfigAction, MayVehicle } from '../../type';
import { SET_GLOBAL_CONFIG } from '../actionTypes';

const initialState: GlobalConfig = {
  may_ids: new Map<string, MayVehicle>(),
};

export default function (state = initialState, action: GlobalConfigAction) {
  switch (action.type) {
    case SET_GLOBAL_CONFIG: {
      return { ...action.globalConfig };
    }
    default:
      return state;
  }
}
