import React from 'react';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { Layer, LayerProps, Source } from 'react-map-gl';

const RoutePolyline = (props: RoutePolylineProps) => {
  const { id, points, color, lineWidth } = props;

  const lineLayer: LayerProps = {
    id: `route-line-${id}`,
    type: 'line',
    source: `route-${id}`,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': color,
      'line-width': lineWidth,
    },
  };

  const geojson: Feature<Geometry, GeoJsonProperties> = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: points,
    },
  };

  return (
    <Source id={`route-${id}`} type='geojson' data={geojson}>
      <Layer {...lineLayer} />
    </Source>
  );
};

export interface RoutePolylineProps {
  id: number;
  points: number[][];
  color: string;
  lineWidth: number;
}

export default RoutePolyline;
