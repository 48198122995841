import { LngLatLike, LngLatBounds } from 'mapbox-gl';

export const getBoundsOfLines = (lines: LngLatLike[][]): LngLatBounds => {
  const bounds = new LngLatBounds();
  for (const line of lines) {
    for (const point of line) {
      bounds.extend(point);
    }
  }
  return bounds;
};
