const getApiUrl = (): string => {
  if (process.env.NODE_ENV === 'production') {
    return '/api';
  } else {
    return 'http://127.0.0.1:3000/api';
  }
};

export const API_URL = getApiUrl();
export const ACCESS_TOKEN =
  'pk.eyJ1IjoiZ3JpZmZpbm9iZWlkIiwiYSI6ImNrbTBvdTJ2cjB2NXMyb21yYWE1Y3V3OWQifQ.fFaSlhp8gAbjJgaN-H9c9Q';

const TEN_MINUTES_IN_SEC = 600;
export const OBSOLETE_DATA_TIMEOUT_SEC = 2 * TEN_MINUTES_IN_SEC; // How many seconds until realtime data is considered obsolete

export const GLOBAL_CONFIG_URL =
  'https://global-config.maymobility.com/global_config.json';

export const MAY_MOBILITY_COLORS = {
  BLACK: '#1F1F1F',
  GREEN: '#369040',
  LIGHT_BLUE: '#00BCEE',
  LIGHT_GREEN: '#98C949',
  ORANGE: '#FF9C17',
  WHITE: '#FFFFFF',
};
